
html {
  scroll-behavior: smooth;
}


.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}

.loader-companies{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.MuiDialog-paper{
  border-radius: 16px !important;
}


.statsBoxVisible {
  transform: translateX(0);
  opacity: 1;
}

.statsBoxHidden {
  transform: translateX(-100%);
  opacity: 0;
  pointer-events: none; /* Disable pointer events when hidden */
}

::-webkit-scrollbar {
  width: 10px !important;
}
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 10px !important;
}
::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172) !important;
  border-radius: 5px !important;
}
