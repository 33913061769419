.MuiOutlinedInput-root.text {
    outline: none !important;
}

.MuiOutlinedInput-root.text:focus,
.MuiOutlinedInput-root.active {
    outline: 3px solid green !important;
    border-color: 3px green !important;
    color: brown;

}



.navbar-list span {
    font-size: 1rem;
    font-weight: 600;
}

.MuiListItemIcon-root {
    min-width: 40px !important;
}

/* .selectedNavItem{
    background-color: #4effca !important;
    color: rgb(0, 0, 0) !important;
}

.selectedNavItem svg{
    color: rgb(0, 0, 0) !important;
} */

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.noSelect:focus {
    outline: none !important;
}

.link-click{
    /* -webkit-tap-highlight-color: #EAAA00;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    cursor: default;
}
.link-click:focus {
    outline: none !important;
}