.date-range-picker{
  z-index: 1000;
  position: absolute;
  right: 0;
  top: 20px;
  min-width: 700px;
}

[class*="materialui-react-daterange-picker-MuiIconButton-label-"]{
  color: #2D3047 !important;
}

[class*="materialui-react-daterange-picker-MuiPaper-elevation0-"]{
  background-color: #FFFFFF !important;
  color: #2D3047 !important;
  border-radius: 16px !important;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.12) !important;
}

[class*="materialui-react-daterange-picker-makeStyles-highlighted-"]{
  background-color: rgba(247, 164, 29, 0.08) !important;
  color: #2D3047 !important;
}

[class*="materialui-react-daterange-picker-MuiPaper-elevation5-"]{
  background-color: #FFFFFF !important;
  color: #2D3047 !important;
  border-radius: 16px !important;
}

[class*="materialui-react-daterange-picker-MuiInputBase-input-"]{
  color: #2D3047 !important;
}

[class*="materialui-react-daterange-picker-MuiSelect-nativeInput-"]{
  color: #2D3047 !important;
}

[class*="materialui-react-daterange-picker-MuiTypography-root-"]{
  color: #2D3047 !important;
}

[class*="materialui-react-daterange-picker-makeStyles-filled-"]{
  background-color: #F7A41D !important;
  color: #FFFFFF !important;
}

[class*="materialui-react-daterange-picker-MuiSvgIcon-root-" ]{
  color: #2D3047 !important;
}

[class*="materialui-react-daterange-picker-makeStyles-filled-"] span p {
  color: #FFFFFF !important;
}

[class*="materialui-react-daterange-picker-MuiTypography-colorTextSecondary-"]{
  color: rgba(45, 48, 71, 0.6) !important;
}

[class*="materialui-react-daterange-picker-MuiTypography-caption-"]{
  color: #2D3047 !important;
}


[class*="materialui-react-daterange-picker-MuiButton-root-"]{
  background-color: #F7A41D !important;
  color: #FFFFFF !important;
  border-radius: 16px !important;
  padding: 10px 20px !important;
}

/* date range button */
/* .css-arz92a-MuiButtonBase-root-MuiButton-root{
  border: 1px solid #cecece !important;
  color: #000000 !important;
} */




.dp-range-picker-container{
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  z-index: 0;
  position: fixed;
}